body, html{
  /*background-color: #002e01;
  background: #002e01;;*/
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#002e01  !important;;
}


html *
{
  color: #002e01 !important;
}




.MuiButton-containedPrimary
{
  color: #fff  !important;
  background-color: #2d8f0d !important;
}



/**************************************** HEADER **************************************/
header, .Header
{
  background: #002e01 !important;
  box-shadow: none  !important;
  color: #fff !important;
}

.Header a
{
  text-decoration: none;
}

.Header *
{
  color: #fff !important;
}

.Header .Title
{
  font-weight: 600;
}

.Header .Title, .Header .AppsIcon, .Header .ProfileIcon, .Header  .NotificationsIcon
{
  color: #fff;
}

.BeadcrumToolBar
{
  border-bottom: #002e01 solid 2px;
  min-height:36px !important
}

.BeadcrumToolBar *
{
  font-size: 16px !important; 
}





/**************************************** Loading ************************************/
.loading-container {
  align-items: center;
  text-align: center;
  margin-top: 30px;
  color: #002e01;
}

.loading-container-green {
  align-items: center;
  text-align: center;
  margin-top: 30px;
  color: #002e01;
}

.loading-dots {
  display: flex;
  justify-content: center;
}

.dot {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 5px;
  background-color: #fff;
  border-radius: 50%;
  opacity: 0;
  animation: dot-flashing 1.5s infinite;
}

.loading-container-green .dot {
  background-color: #002e01;
}

.dot:nth-child(1) {
  animation-delay: 0.2s;
}

.dot:nth-child(2) {
  animation-delay: 0.4s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes dot-flashing {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-text {
  margin-top: 10px;
  font-size: 30px;
}



/**************************************** Login ************************************/


.LoginPage {
  color: #fff;
  text-align: center;
  width: 80%;
  max-width: 340px;
  margin: auto;
  background: #002e01;
  margin-top: 40px;
  padding-bottom: 30px;
  border-radius: 60px;
}

.LoginPage .Heading
{
 color: #fff;
 text-align: center;
 margin: 0;
 margin-bottom: 30px;
 font-size: 40px;
}

.LoginPage .Logo
{
  margin-top: 30px ;
  margin-bottom: 15px ;
  max-width: 150px;
  width: 100%;
}

.LoginPage * 
{
  color: #fff !important;
}


.firebaseui-idp-button  {
  background: #fff !important;
  background-color: #fff !important;
  color:#002e01 !important;
}

.firebaseui-idp-google>.firebaseui-idp-text
{
  color:#002e01 !important;
}

.firebaseui-idp-password {
  background: #2d8f0f !important;
  background-color: #2d8f0f !important;
}








/************************************ Page404 *******************************************/


.Page404
{
  text-align: center;
  width: 80%;
  max-width: 200px;
  margin: auto;
  font-size: 20px  !important;
}




/************************************ Home View *******************************************/


.AddButton:hover
{
  background-color: #a3e2bc !important;
}